import classNames from 'classnames'

export const pageLayout =
  'mx-auto flex min-h-screen flex-col items-center relative'

const layoutSectionClasses = {
  pagePx: 'px-4 sm:px-6 xl:px-10',
  maxWidth: 'max-w-[1440px]',
}

export const sectionPaddingY = {
  sm: 'py-4',
  lg: 'py-4 md:py-10 xl:py-12',
}

export const sectionPaddingTop = {
  sm: 'pt-4',
  lg: 'pt-4 md:pt-10 xl:pt-12',
}

export const gapClasses = {
  gap: 'gap-4 sm:gap-4 md:gap-5 lg:gap-6',
  rowGap: 'gap-y-4 sm:gap-y-4 md:gap-y-5 lg:gap-y-6',
  rowGap2: 'gap-y-5 sm:gap-y-6 md:gap-y-8 lg:gap-y-10',
  colGap: 'gap-x-4 sm:gap-x-4 md:gap-x-5 lg:gap-x-6',
}

export const gridClasses = {
  base: 'mx-auto grid',
  gridCols: 'grid-cols-4 sm:grid-cols-6 md:grid-cols-8 xl:grid-cols-12',
}

export const gridChildClasses = {
  fullWidth: 'col-start-1 col-end-[-1]',
  narrow1: 'col-start-1 col-end-[-1] md:col-start-2 md:col-end-[-2]',
  narrow2:
    'col-start-1 col-end-[-1] md:col-start-2 md:col-end-[-2] xl:col-start-3 xl:col-end-[-3]',
  narrow4:
    'col-start-1 col-end-[-1] sm:col-start-2 sm:col-end-[-2] md:col-start-3 md:col-end-[-3] xl:col-start-5 xl:col-end-[-5]',
  subgrid: 'grid grid-cols-subgrid',
}

export const defaultSectionWithoutGrid = classNames(
  'mx-auto w-full',
  layoutSectionClasses.maxWidth,
  layoutSectionClasses.pagePx,
)

export const defaultGrid = classNames(
  'mx-auto w-full',
  layoutSectionClasses.maxWidth,
  layoutSectionClasses.pagePx,
  gridClasses.base,
  gridClasses.gridCols,
  gapClasses.colGap,
)

export const cardClasses = {
  px: 'px-4 sm:px-6 md:px-8',
  pt: 'pt-4 sm:pt-6 md:pt-8',
  pb: 'pb-4 sm:pb-6 md:pb-8',
  p: 'p-4 sm:p-6 md:p-8',
  bg: 'bg-white',
  radius: 'rounded-lg',
  shadow: 'shadow-01',
}

export const cardPadding = classNames(
  cardClasses.px,
  cardClasses.pt,
  cardClasses.pb,
)

export const defaultCard = classNames(
  cardClasses.shadow,
  cardPadding,
  cardClasses.bg,
  cardClasses.radius,
)

export const flexClasses = {
  stack: classNames('flex flex-col', gapClasses.gap),
}
